<template>
  <div
    id="Skedoole"
    style="overflow: hidden !important; height: 100vh !important"
  >
    <notifications
      position="top right"
      classes="custom-notify vue-notification"
    />

    <Pagamento />

    <Navbar
      v-if="$route && $route.meta.layoutCompleto && $dadosSessao != undefined"
    />

    <div class="ml-5 pl-4">
      <b-breadcrumb
        v-if="$route && $route.meta.layoutCompleto && $dadosSessao != undefined"
        :items="items"
      ></b-breadcrumb>
    </div>

    <b-overlay
      :show="isLoading"
      variant="ligth"
      opacity="0.85"
      blur="2px"
      rounded="sm"
      style="width: 100vw; height: 100vh"
    >
      <template #overlay>
        <b-col
          cols="12"
          class="d-flex justify-content-center mb-5"
          style="width: 6vw"
          ><b-img :src="require('./assets/fade-stagger-squares.svg')"> </b-img
        ></b-col>
      </template>
      <router-view
        :class="
          $route && $route.meta.layoutCompleto
            ? 'ml-5 pl-2 text-center container-navbar-router-view'
            : 'pl-2 container-vazio-router-view'
        "
      >
      </router-view>
    </b-overlay>
  </div>
</template>


<script>
import Vue from "vue";
import Mixin from "./core/Mixin";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [Mixin],
  data: function () {
    return {
      alturaRouterView: "80vh",
      alturaComandaAberta: "20vh",
      exibirContainerComanda: true,
      comandaAberta: {},
      iconComandaAberta: "arrows-angle-contract",
      componentKeyComanda: 0,
      exibirBotaoComanda: false,
      items: this.$routeHistory,
    };
  },

  async created() {
    if (this.$route.query && this.$route.query.empresa) {
      Vue.prototype.$subdominio = this.$route.query.empresa;
    } else {
      Vue.prototype.$subdominio = Vue.prototype.$subdominio
        ? Vue.prototype.$subdominio
        : "Skedoole";
    }

    /*TO DO:
      Criar requisicao que buscará o css do subdominio

      - Se nao houver subdominio, irá redirecionar para selecao de subdominios.
    */
  },

  mounted() {
    this.changeStyle();
  },

  methods: {
    ...mapActions(["setLoading"]),

    changeStyle() {
      // const sheets = document.styleSheets;
      // const classesParaAlterar = new Set([
      //   ".btn-primary",
      //   ".v-sidebar-menu",
      //   ".vsm--link_level-1",
      //   ".vsm--icon",
      // ]); // Usar um Set para verificar de forma mais eficiente
      // for (let i = 0; i < sheets.length; i++) {
      //   const rules = sheets[i].cssRules || sheets[i].rules;
      //   if (!rules) continue; // Ignorar folhas de estilo que não possuem regras
      //   for (let j = 0; j < rules.length; j++) {
      //     const rule = rules[j];
      //     if (rule.selectorText) {
      //       const selectors = rule.selectorText.split(",").map((s) => s.trim());
      //       selectors.forEach((selector) => {
      //         if (classesParaAlterar.has(selector)) {
      //           rule.style.setProperty("background-color", "red");
      //         }
      //       });
      //     }
      //   }
      // }
    },
    obterRotaComComanda(route) {
      let rotasComComanda = ["Selecao dia mes", "Servicos", "Selecao Hora"];

      if (rotasComComanda.includes(route)) {
        this.exibirBotaoComanda = true;
        return true;
      } else {
        this.exibirBotaoComanda = false;

        return false;
      }
    },

    async exibirComanda(aberto) {
      this.comandaAberta = await this.$getStore("comanda");
    },
  },

  async updated() {
    this.componentKeyComanda = +1;
    this.comandaAberta = await this.$getStore("comanda");

    this.obterRotaComComanda(this.$route.name);
    this.items = this.$routeHistory;
  },

  computed: {
    ...mapGetters(["isLoading"]),
  },
};
</script>


<style lang="scss">
#app {
  //font-family: Verdana, Geneva, sans-serif;
  color: #282a35;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  // background-color: #f2f2f2 !important;
}

.container-route-name {
  // color: #f2f2f2 !important;
  background-color: rgb(0, 96, 177);
}

.route-name {
  font-weight: 540;
}

.notification-content {
  font-size: 15px;
  font-weight: 400 !important;
  background-color: var(--cor-cards);
  padding-top: 18px;
}

.notification-title {
  font-weight: 700 !important;
  font-size: 17px;

  white-space: nowrap;
  background-color: var(--cor-cards);
}

.vue-notification.success {
  padding: 15px;
  background: var(--cor-cards) !important;
  border-left: 20px solid #42a85f;
}

.vue-notification.warn {
  padding: 15px;
  background: var(--cor-cards) !important;
  border-left: 20px solid #f48a06;
}

.vue-notification.error {
  padding: 15px;
  background: var(--cor-cards) !important;
  border-left: 20px solid #b82e24;
}
</style>

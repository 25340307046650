<template>
  <CustomModal
    ref="componente-pagamentos-global"
    size="xl"
    hideHeaderClose
    noCloseOnBackdrop
    scrollable
    title="Pagamento"
  >
    <b-row class="container-informacoes-compra mt-0">
      <b class="texto-container-pagamento fonte-customizada">{{
        dadosComanda?.aguardandoPagamento?.name
      }}</b>

      <b class="texto-titulos-container-pagamento ml-1">
        {{ dadosComanda?.aguardandoPagamento?.description }}
      </b>

      <b class="texto-titulos-container-pagamento pt-3"
        >Valor total da compra:
        <b class="texto-container-pagamento fonte-customizada">{{
          parseFloat(dadosComanda?.aguardandoPagamento?.value).toLocaleString(
            "pt-BR",
            {
              style: "currency",
              currency: "BRL",
            }
          )
        }}</b>
      </b>
    </b-row>

    <CustomForm
      rowContainerClass="container-detalhes-pagamento mt-1 pb-5"
      @change-form-values="gerarInformacoesPagamento"
      ref="formularioPagamento"
      :opcoes="inputsPagamento"
      :dados="{}"
    />

    <div v-if="inputsDetalhesPagamentoCartao.length > 0">
      <CustomForm
        rowContainerClass="container-detalhes-pagamento mt-1 pb-5"
        ref="formularioDetalhesPagamentoCartao"
        :opcoes="inputsDetalhesPagamentoCartao"
        :dados="{}"
      />
    </div>

    <div
      v-if="dadosCobranca.payload && inputsDetalhesPagamentoPix.length > 0"
      style=""
      class="text-center"
    >
      <CustomForm
        ref="formularioDetalhesPagamentoPix"
        :opcoes="inputsDetalhesPagamentoPix"
        :dados="{ ...dadosCobranca.payload }"
        v-on:form-input-buttom-pressed="
          (toExecute, inputValue) => executarMetodoEval(toExecute, inputValue)
        "
      />
      <ImageThumb
        :hasDetailsModal="true"
        :rounded="false"
        :altura="300"
        :thumbnail="false"
        :largura="300"
        :src="`data:image/png;base64,${lodash.get(
          dadosCobranca,
          'payload.encodedImage',
          ''
        )}`"
      />
      <div class="mt-2">
        <Timer
          label="O código de pagamento expira em:"
          :endDate="
            new Date(lodash.get(dadosCobranca, 'payload.expirationDate', ''))
          "
        />
      </div>
    </div>

    <template #modal-footer>
      <GroupButton
        rowContainerClass="d-contents"
        v-if="botoesAcaoModal.length > 0"
        v-on:group-button-click="executarMetodoEval"
        :opcoes="botoesAcaoModal"
      />

      <GroupButton
        rowContainerClass="d-contents"
        v-if="botoesAcaoPagamentoCartao.length > 0"
        v-on:group-button-click="executarMetodoEval"
        :opcoes="botoesAcaoPagamentoCartao"
      />
    </template>
  </CustomModal>
</template>

<script>
import UtilsPagamento from "./utils/pagamento";
import SocketConsumer from "../../consumer";
import { EventBus } from "../event-bus";
import Mixin from "../core/Mixin";

import ServicosPagamento from "../services/payments";

export default {
  mixins: [Mixin],
  data() {
    return {
      conexaoSocketPagamento: null,
      dadosComanda: {},
      inputsPagamento: [],
      inputsDetalhesPagamentoCartao: [],
      inputsDetalhesPagamentoPix: [],
      botoesAcaoModal: [],
      botoesAcaoPagamentoCartao: [],

      dadosCobranca: {},
    };
  },

  created() {
    EventBus.$on("componente-pagamentos-global", this.exibirModal);
  },

  mounted() {},

  methods: {
    async enviarDadosPagamentoCartao() {
      try {
        this.$notify({
          title: "Pagamento",
          text: "O seu pagamento está sendo processado, por favor aguarde...",
          duration: 5000,
          type: "success",
        });

        this.setLoading(true);
        let dados =
          this.$refs.formularioDetalhesPagamentoCartao.obterDadosFormulario();

        await ServicosPagamento.enviarPagamentoCartao({
          idCobranca: this.lodash.get(this.dadosCobranca, "id", ""),
          userId: this.$dadosSessao.user.id,
          ...dados,
        });
      } catch (e) {
        this.$notify({
          title: "Houve uma falha no pagamento",
          text: `${this.lodash.get(
            e,
            "response.data.errors.base",
            "Tente novamente mais tarde ou entre em contato com o suporte"
          )}`,
          duration: 5000,
          type: "error",
        });
      } finally {
        this.setLoading(false);
      }
    },

    processarRetornoPagamento(retorno) {
      if (retorno.id != 2) {
        this.$notify({
          title: "Houve uma falha.",
          text: `Situação do pagamento: ${retorno.name}`,
          duration: 5000,
          type: "error",
        });

        return;
      }

      this.concluirPagamento();
    },

    concluirPagamento() {
      this.$setStoreComanda({
        aguardandoPagamento: {},
        pagamentoConcluido: {
          item: this.dadosComanda.aguardandoPagamento,
          detalhesPagamento: {
            ...this.dadosCobranca,
          },
        },
      });

      this.$notify({
        title: "Pagamento concluído.",
        text: ``,
        duration: 2000,
        type: "success",
      });

      EventBus.$emit("pagamento-concluido");

      this.fecharModal();
    },

    copiarParaAreaDeTransferencia(codigo) {
      navigator.clipboard.writeText(codigo);

      this.$notify({
        title: "Código PIX copiado.",
        text: ``,
        duration: 1000,
        type: "success",
      });
    },

    async gerarInformacoesPagamento(dados) {
      try {
        this.setLoading(true);

        this.inputsDetalhesPagamentoCartao = [];
        this.inputsDetalhesPagamentoPix = [];
        this.botoesAcaoPagamentoCartao = [];
        this.dadosCobranca = {};

        let tipoCobranca = this.lodash.get(
          this.dadosComanda,
          "aguardandoPagamento.tipoCobranca",
          ""
        );
        let faturaGerada;

        this.processarInputsPagamento(dados);

        if (tipoCobranca == "ASSINATURA_PLANO_CLIENTE") {
          faturaGerada = await ServicosPagamento.gerarPagamentoPlano({
            billingType: dados.billingType.id,
            userId: this.$dadosSessao.user.id,
            headquarterId: this.$dadosSessao.filial.id,
            idPlano: this.lodash.get(
              this.dadosComanda,
              "aguardandoPagamento.id",
              ""
            ),
          });
        }

        this.dadosCobranca = this.lodash.get(faturaGerada, "data", {});

        this.criarCanalSocket();
      } catch (e) {
        this.fecharCanalSocket();

        this.$notify({
          title: "Não foi possível gerar a cobrança",
          text: `${this.lodash.get(
            e,
            "response.data.errors.base",
            "Tente novamente mais tarde ou entre em contato com o suporte"
          )}`,
          duration: 5000,
          type: "error",
        });
      } finally {
        this.setLoading(false);
      }
    },

    processarInputsPagamento(dados) {
      this.inputsDetalhesPagamentoCartao = [];
      this.inputsDetalhesPagamentoPix = [];

      switch (dados.billingType.id) {
        case 2:
          this.inputsDetalhesPagamentoPix = UtilsPagamento.obterInputsPix();

          return { message: "Processando pagamento via PIX" };

        case 1:
          this.inputsDetalhesPagamentoCartao =
            UtilsPagamento.obterInputsCartaoCredito();

          this.botoesAcaoPagamentoCartao =
            UtilsPagamento.obterBotoesAcaoPagamentoCartao();

          return { message: "Processando pagamento via Cartão de crédito" };

        default:
          return { message: "Tipo de pagamento não suportado" };
      }
    },

    obterBotoesAcao() {
      this.botoesAcaoModal = UtilsPagamento.obterBotoesAcaoModalPagamento();
    },

    obterInputsTipoPagamento() {
      this.inputsPagamento = UtilsPagamento.obterInputsTipoPagamento();
    },

    criarCanalSocket() {
      console.log("Starting connection to WebSocket Server");

      this.conexaoSocketPagamento = SocketConsumer.subscriptions.create(
        {
          channel: `PaymentStatusChannel`,
          id: this.lodash.get(this.dadosCobranca, "id", ""),
        },
        {
          connected: () => {
            console.log("Connected to PaymentStatusChannel");
          },
          disconnected: () => {
            console.log("Disconnected from PaymentStatusChannel");
          },
          received: (data) => {
            console.log("Received data: SOCKET", data);
            this.processarRetornoPagamento(data);
          },
          rejected: (data) => {
            console.log("Rejected connection:", data);
            this.fecharCanalSocket();
          },
        }
      );
    },

    fecharCanalSocket() {
      if (this.conexaoSocketPagamento) {
        this.conexaoSocketPagamento.unsubscribe(); // Fecha a conexão com o canal
        console.log("Canal desconectado");
      } else {
        console.log("Não há canal para desconectar");
      }
    },

    sendMessage() {
      console.log("Sending message");
      this.conexaoSocketPagamento.perform("notify", {
        message: "Test message from Vue component",
      });
    },

    async exibirModal(nomeModal = "componente-pagamentos-global") {
      if (this.$refs[nomeModal]) {
        await this.$nextTick();

        this.dadosComanda = this.$getStore("comanda");
        this.$refs[nomeModal].exibirModal();

        this.obterInputsTipoPagamento();
        this.obterBotoesAcao();
      }
    },

    async fecharModal(nomeModal = "componente-pagamentos-global") {
      if (this.$refs[nomeModal]) {
        this.$refs[nomeModal].fecharModal();
      }

      this.fecharCanalSocket();
      this.limparFormularios();
    },

    limparFormularios() {
      this.conexaoSocketPagamento = null;
      this.dadosComanda = {};
      this.inputsPagamento = [];
      this.inputsDetalhesPagamentoCartao = [];
      this.inputsDetalhesPagamentoPix = [];
      this.botoesAcaoModal = [];
      this.botoesAcaoPagamentoCartao = [];
      this.dadosCobranca = {};
    },
  },

  beforeDestroy() {
    this.fecharCanalSocket();
  },

  props: {
    colXL: {
      type: Number,
      required: false,
    },
    colLG: {
      type: Number,
      required: false,
    },
    colMD: {
      type: Number,
      required: false,
    },
    colSM: {
      type: Number,
      required: false,
    },
    cols: {
      type: Number,
      required: false,
    },
    titulo: {
      type: String,
      required: false,
    },
    subtitulo: {
      type: String,
      required: false,
    },
    containerClass: {
      type: String,
    },
  },
};
</script>

<style>
.texto-container-pagamento {
  font-size: 22px;
}

.texto-titulos-container-pagamento {
  font-size: 14px;
  font-weight: 300;
}
.container-informacoes-compra {
  padding: 10px; /* Espaçamento interno */
  background-color: var(--cor-cards) !important;
  border-radius: 5px;
}
.container-detalhes-pagamento {
  padding: 10px; /* Espaçamento interno */
  background-color: var(--cor-fundo) !important;
}
</style>

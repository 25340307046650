import api from "../http/index"

class AuthServices {
  
  async enviarAutenticacao(dados) {
    let { login, password, subdomain } = dados
    
    let dadosRetorno = {};
  
    try {
      let isAuth = false;
  
      const usuario =  {
          password: password,
          subdomain: subdomain,
          login: login
      }
  
      const response = await api({
        method: 'post',
        url:  `/auth/sign_in?key_transform_camel_lower=true`,
        data: {
          ...usuario
        }
      });
  
      if (response && response.data.user) {
        isAuth = true;
      };
  
      let { uid, client, expiry  } = response.headers;
      let accessToken = response.headers['access-token']
      let { data : detalhesUsuarioLogado } = response;
  
      dadosRetorno = {
        isAuth,
        uid,
        accessToken,
        client,
        expiry,
        ...detalhesUsuarioLogado
      }
  
      return dadosRetorno;
  
    } catch (error) {
      console.error(`Ocorreu um erro ao realizar o login: ${error}`);
    }
  }

  async obterCodigoRedefinicaoSenha({cellphone, subdomain}){
    try{
      let retorno = await api({
        method: "POST",
        url: `/auth/cellphone_recovery/generate_code`,
        data: {
          cellphone: cellphone,
          subdomain: subdomain,
        },
      });

      return retorno;
    }catch(e){
      console.error(`Ocorreu um erro ao solicitar o código de redefinição da senha. Detalhes: ${e}`)
    };
  };

  async validarCodigoRedefinicaoSenha({cellphone, subdomain, code}){
    try{
      let retorno = await api({
        method: "POST",
        url: `/auth/cellphone_recovery/validate_code`,
        data: {
          cellphone: cellphone,
          code: code,
          subdomain: subdomain,
        },
      });

      return retorno;
    }catch(e){
      console.error(`Ocorreu um erro ao validar o código de redefinição da senha. Detalhes: ${e}`)
    };
  };

  async enviarNovaSenhaRecuperacaoSenha({senha, confirmacaoSenha, validacaoCodigo}){
    try{
      let retorno = await api({
        method: "PATCH",
        url: `/auth/password`,
        headers: {
          ...validacaoCodigo
        },
        data: {
          password: senha,
          password_confirmation: confirmacaoSenha,
        },
      });

      return retorno;
    }catch(e){
      console.error(`Ocorreu um erro ao alterar a senha. Detalhes: ${e}`)
    };
  };

  async criarConta({user, empresa}){
    try{
      let retorno = await api({
        method: "post",
        url: `external/users?subdomain=${empresa}`,
        data: {
          user: { ...user },
        },
      });

      return retorno;
    }catch(e){
      console.error(`Ocorreu um erro ao criar a conta. Detalhes: ${e}`)
    };
  };
 
  async obterInformacoesSubdominio({ empresa }){
    try{
      let retorno = await api({
        method: "get",
        url: `/external/account_by_subdomain`,
        params: {
          subdomain: empresa,
        },
      });

      return retorno;
    }catch(e){
      console.error(`Ocorreu um erro ao obter os dados da conta. Detalhes: ${e}`)
    };
  };
  
}

export default new AuthServices()
